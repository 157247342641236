import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faLock } from "@fortawesome/free-solid-svg-icons";
import "./loginPage.css";
import SpinnerLoader from "../../spinnerLoader/SpinnerLoader";
import { logIn } from "../../../helpers/api";
import { Alert, Spinner } from "react-bootstrap";
import Vegas from "../../vegas/Vegas";

export default function LoginPageCardex() {
  const [showSpinner, setShowSpinner] = useState(true);
  const [user, setUser] = useState({ username: "", password: "" });
  const [showLoading, setShowLoading] = useState(false);
  const [loginError, setloginError] = useState("");
  const navigate = useNavigate();
  const handlerUsername = (e) => {
    e.preventDefault();
    setUser({ ...user, username: e.target.value });
  };

  const handlerPassword = (e) => {
    e.preventDefault();
    setUser({ ...user, password: e.target.value });
  };

  const handlerLogin = (e) => {
    setShowLoading(true);
    e.preventDefault();
    logIn(user)
      .then((response) => {
        setShowLoading(false);
        navigate("/applications");
      })
      .catch((error) => {
        setShowLoading(false);
        setloginError(error);
      });
  };

  useEffect(() => {
    setShowSpinner(false);
  }, []);

  useEffect(() => {
    setloginError("");
  }, [user]);

  const Vehicule = () => (
    <div className="ig-login-car">
      <Vegas />
    </div>
  );

  const longInForm = () => {
    return (
      <>
        <Container className="ig-login-loginform">
          <img alt="Cardex brand" src="img/bigCardex.png" />
          <h2 style={{ fontWeight: "bold" }} className="w-100 text-end">
            Log In
          </h2>
          <p className="w-100 text-end">Log in to continue in our website</p>

          {loginError !== "" ? (
            <Alert variant="warning">{loginError}</Alert>
          ) : (
            <></>
          )}

          <Form style={{ color: "#555" }}>
            <Form.Group className="text-end" controlId="formBasicEmail">
              <Form.Control
                className="text-end ig-login-input"
                type="email"
                placeholder="Email Address"
                value={user.username}
                onChange={handlerUsername}
              />
            </Form.Group>
            <div
              style={{
                width: "fit-content",
                left: "0.5em",
                position: "relative",
                transform: "translateY(-135%)",
              }}
            >
              <FontAwesomeIcon icon={faEnvelope} />
            </div>

            <Form.Group className="mt-2 text-end" controlId="formBasicPassword">
              <Form.Control
                className="text-end ig-login-input"
                type="password"
                placeholder="Password"
                value={user.password}
                onChange={handlerPassword}
              />
            </Form.Group>
            <div
              style={{
                width: "fit-content",
                left: "0.5em",
                position: "relative",
                transform: "translateY(-135%)",
              }}
            >
              <FontAwesomeIcon icon={faLock} />
            </div>
            <Form.Group className="text-end">
              <Button
                variant="warning"
                className="ig-login-btn"
                type="submit"
                onClick={handlerLogin}
                disabled={showLoading}
              >
                {showLoading ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  <></>
                )}
                Log In
              </Button>
            </Form.Group>
          </Form>
        </Container>
      </>
    );
  };

  return (
    <div>
      <div>
        {Vehicule()}
        {longInForm()}
        <SpinnerLoader show={showSpinner} />
      </div>
    </div>
  );
}
